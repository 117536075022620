import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormData } from '../../helpers/FormData';
import { _templateCreate, _templateEdit, _templateGetById } from '../../services/apiConnections.service';
import { SmsCalculator } from '../elements/smsCalculator.component';

export const SmsTemplateUpsert = (props) => {

    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        name: '',
        content: ''
    });
    const [formErrors, setFormErrors] = useState({});

    let params = useParams();

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        if (props.edit && token) {
            handleTemplateGet(token);
        }
    }, [token, props]);

    const handleTemplateGet = async (token) => {
        let result = await _templateGetById(token, params.id);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            console.log("--result:", result);
            setFormValues({
                'name': result.name,
                'content': result.content
            });
        }
        setError(result.error);
    };

    const handleUpsert = async (event) => {
        event.preventDefault();
        let result;

        if (!props.edit)
            result = await _templateCreate(token, formValues);
        else
            result = await _templateEdit(token, formValues, params.id);
        if (result.error) {
            setError(result.error);
        } else {
            console.log("success");
            props.navigate('/sms-templates');
        }
    };

    const handleValidation = (target) => {

        switch (target.name) {
            case 'name':
                // name validation
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        name: "Cannot Be Empty"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['name'];
                    setFormErrors(copy);
                }
                break;
            case 'content':
                // content validation
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        content: "Cannot Be Empty"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['content'];
                    setFormErrors(copy);
                }
                break;

            default: break;
        }
    };

    const extractTemplateVars = (template) => {
        const regex = /\{\{([0-9a-zA-Z_]*?)\}\}/g;

        let match, matches = [];
        while ((match = regex.exec(template)) !== null) {
            matches.push(match[1]);
        }
        return matches;
    };

    useEffect(() => {
        setWarning('');
        let vars = extractTemplateVars(formValues.content);
        if (vars.length) {
            setWarning('Message length depends on variables');
        }
    }, [formValues.content]);

    const formIsValid = () => {
        return !Object.keys(formErrors).length && formValues.name.length && formValues.content.length;
    };

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>{!props.edit ? 'Create Template' : 'Edit Template'}</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='pageContent'>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='name'>Template Name</label>
                        <div className='inputWrapper inputWrapperNoMargin'>
                            <input
                                id='name'
                                name='name'
                                type="text"
                                className={`formInput ${formErrors.name ? 'invalidInput' : ''}`}
                                placeholder="Name Your Template"
                                value={formValues.name}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'name',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.name}</span>
                        </div>
                    </div>
                    <SmsCalculator
                        value={formValues.content}
                        onChange={(event) => handleFormValueChange(
                            'content',
                            event.target.value
                        )}
                        onKeyUp={(event) => { handleValidation(event.target) }}
                        warning={warning}
                        fieldError={formErrors.content} />
                    <div className='buttonBlock'>
                        <button
                            onClick={handleUpsert}
                            className={`mainBtn btn ${formIsValid() ? '' : 'disabledBtn'}`}
                            disabled={!formIsValid()}>{props.edit ? 'Save' : 'Create'}</button>
                    </div>
                </div>
            </div>
        </div >
    );
};