import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const Cancel = () => {

    let navigate = useNavigate();

    const redirectToDashboard = () => {
        navigate('/dashboard');
    }

    return (
        <Modal
            className=''
            show={true}
            backdrop="static"
            keyboard={false}
            centered>
            <Modal.Header>
                <div className='pageTitle'>
                    <span>Payment Cancel</span>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>Don't get discouraged and try again</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className='btn mainBtn acceptBtn'
                    onClick={() => redirectToDashboard()}>Ok</button>
            </Modal.Footer>
        </Modal>
    )
};