import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';

import { FormData } from '../../helpers/FormData';
import { _profileGet, _profileSave } from '../../services/apiConnections.service';

export const Profile = () => {

    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        first_name: '',
        last_name: '',
        password: '',
        confirm_password: ''
    });
    const [formErrors, setFormErrors] = useState({});

    let navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        if (token) {
            handleProfileGet(token);
        }
    }, [token]);

    const handleProfileGet = async (token) => {
        let result = await _profileGet(token);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            // console.log("--result:", result);
            setFormValues({
                'first_name': result.first_name,
                'last_name': result.last_name,
            });
        }
        setError(result.error);
    };

    const handleSave = async (event) => {
        event.preventDefault();
        let result = await _profileSave(token, formValues);

        if (result.error) {
            setError(result.error);
        } else {
            toast.success(`Your changes in personal information were saved successfully`);
        }
    };

    const handleCancel = () => {
        navigate('/dashboard');
    };

    const handleValidation = (target) => {
        switch (target.name) {
            case 'first_name':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        first_name: "First Name Is Required"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['first_name'];
                    setFormErrors(copy);
                }
                break;
            case 'last_name':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        last_name: "Last Name Is Required"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['last_name'];
                    setFormErrors(copy);
                }
                break;
            case 'password':
                if (target.value != formValues.confirm_password) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        confirm_password: "Must Match Your New Password"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['confirm_password'];
                    setFormErrors(copy);
                }
                break;
            case 'confirm_password':
                if (target.value != formValues.password) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        confirm_password: "Must Match Your New Password"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['confirm_password'];
                    setFormErrors(copy);
                }
                break;
            default: break;
        }
    };

    const formIsValid = () => {
        return !Object.keys(formErrors).length;
    };

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>Personal Information</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='pageContent'>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='first_name'>First Name</label>
                        <div className='inputWrapper'>
                            <input
                                id='first_name'
                                name='first_name'
                                type="text"
                                className={`formInput ${formErrors.first_name ? 'invalidInput' : ''}`}
                                placeholder="First Name"
                                value={formValues.first_name}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'first_name',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.first_name}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='last_name'>Last Name</label>
                        <div className='inputWrapper'>
                            <input
                                id='last_name'
                                name='last_name'
                                type="text"
                                className={`formInput ${formErrors.last_name ? 'invalidInput' : ''}`}
                                placeholder="Last Name"
                                required
                                value={formValues.last_name}
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'last_name',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.last_name}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel'
                            htmlFor='password'>New Password</label>
                        <div className='inputWrapper'>
                            <input
                                id='password'
                                name='password'
                                type="password"
                                className={`formInput ${formErrors.password ? 'invalidInput' : ''}`}
                                placeholder="Password"
                                value={formValues.password}
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'password',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.password}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel'
                            htmlFor='confirm_password'>Confirm Password</label>
                        <div className='inputWrapper'>
                            <input
                                id='confirm_password'
                                name='confirm_password'
                                type="password"
                                className={`formInput ${formErrors.confirm_password ? 'invalidInput' : ''}`}
                                placeholder="Confirm Password"
                                value={formValues.confirm_password}
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'confirm_password',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.confirm_password}</span>
                        </div>
                    </div>
                    <div className='buttonBlock'>
                        <button
                            onClick={handleCancel}
                            className='mainBtn btn marginR15'>Cancel</button>
                        <button
                            onClick={handleSave}
                            className={`mainBtn btn ${formIsValid() ? '' : 'disabledBtn'}`}
                            disabled={!formIsValid()}>Save</button>
                    </div>
                </div>
            </div>
            <Toaster />
        </div >
    );
};