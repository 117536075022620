import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import { FormData } from '../../../helpers/FormData';
import { _getTimeZones, _individualSMSReport, _sendersGet } from '../../../services/apiConnections.service';

export const IndividualSmsReport = () => {

    const [smsReport, setSmsReport] = useState([]);
    const [emptyTable, setEmptyTable] = useState(false);
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);

    const [maxStartTime, setMaxStartTime] = useState(new Date());
    const [maxEndTime, setMaxEndTime] = useState(new Date());
    const [timeZones, setTimeZones] = useState([]);
    const [senders, setSenders] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [filterValues, handleFilterValueChange, setFilterValues] = FormData({
        sender: {
            label: "All",
            value: -1
        },
        recipient: '',
        time_zone: {
            label: 'Europe/Sofia',
            value: 472
        },
        date_from: new Date(
            new Date(
                new Date().setHours(
                    new Date().getHours() - 1
                )
            ).setMinutes(0)
        ),
        date_to: new Date()
    });

    let startOfTheDay = new Date(new Date().setHours(0)).setMinutes(0);
    let browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        if (token && !smsReport.length && !emptyTable) {
            handleFilter();
        }
    }, [token, smsReport, emptyTable]);

    useEffect(() => {
        if (token) {
            handleSenders();
            handleTimeZones();
        }
    }, [token])

    useEffect(() => {
        if (token) {
            handleFilter();
        }
    }, [filterValues]);

    useEffect(() => {
        let now = new Date();
        if (filterValues.date_from.getDate() < now.getDate()) {
            setMaxStartTime(new Date(new Date().setHours(23)).setMinutes(59));
        } else {
            setMaxStartTime(new Date());
        }
        if (filterValues.date_from > filterValues.date_to) {
            handleFilterValueChange('date_to', filterValues.date_from);
        }
    }, [filterValues.date_from]);

    useEffect(() => {
        let now = new Date();
        if (filterValues.date_to.getDate() < now.getDate()) {
            setMaxEndTime(new Date(new Date().setHours(23)).setMinutes(59));
        } else {
            setMaxEndTime(new Date());
        }
    }, [filterValues.date_to]);

    const handleSenders = async () => {
        let result = await _sendersGet(token);
        if (result.error) {
            console.log("error");
            setError(result.error);
        } else {
            let arr = result.senders.map((e) => {
                return {
                    label: e.number,
                    value: e.id
                }
            });
            arr.unshift({
                label: "All",
                value: -1
            })
            setSenders(arr);
        }
    };

    const handleTimeZones = async () => {
        let result = await _getTimeZones(token);
        let arr = result.time_zones.map((e) => {
            return {
                label: e.name,
                value: result.time_zones.indexOf(e)
            }
        });
        setTimeZones(arr);
    };

    useEffect(() => {
        if (timeZones.length) {
            let currentTimeZone = timeZones.find((e) => e.label === browserTimeZone);
            if (JSON.stringify(filterValues.time_zone) !== JSON.stringify(currentTimeZone)) handleFilterValueChange('time_zone', currentTimeZone);
        }
    }, [timeZones]);

    const handleFilter = () => {
        let searchObj = {
            sender: filterValues.sender ? filterValues.sender.label : null,
            recipient: filterValues.recipient || null,
            time_zone: filterValues.time_zone.label || null,
            date_from: dateToLocalISO(filterValues.date_from) || null,
            date_to: dateToLocalISO(filterValues.date_to) || null
        }
        if (filterValues.sender && filterValues.sender.value === -1) searchObj.sender = null;
        let search = `limit=500`;
        for (let e in searchObj) {
            if (searchObj[e] != null) {
                search += `&${e}=${searchObj[e]}`;
            }
        }
        getData(token, search);
    };

    const getData = async (token, search = '') => {
        let result = await _individualSMSReport(token, search);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setEmptyTable(result.messages == null);
            setSmsReport(result.messages == null ? [] : result.messages);
        }
        setError(result.error);
    };

    const dateToLocalISO = (date) => {
        let timeZoneOffset = (new Date(date)).getTimezoneOffset() * 60000;
        let localISOTime = (new Date(date - timeZoneOffset)).toISOString();
        return localISOTime;
    };

    const dateColumnFormatter = (cell, row) => {
        return (
            <span>{moment(new Date(cell)).format('yyyy-MM-DD H:mm')}</span>
        );
    };

    const statusColumnFormatter = (cell, row) => {
        let status = '';
        switch (cell) {
            case 'Scheduled':
                status = 'Scheduled';
                break;
            case 'In progress':
                status = 'In Progress';
                break;
            case 'Sent':
                status = 'Sent';
                break;
            case 'Failed':
                status = 'Failed';
                break;
            case 'SCHEDULED':
                status = 'Scheduled';
                break;
            case 'ENROUTE':
                status = 'Enroute';
                break;
            case 'DELIVERED':
                status = 'Delivered';
                break;
            case 'EXPIRED':
                status = 'Expired';
                break;
            case 'DELETED':
                status = 'Deleted';
                break;
            case 'UNDELIVERABLE':
                status = 'Undeliverable';
                break;
            case 'No route':
                status = 'No Route';
                break;
            case 'Insufficient funds':
                status = 'Insufficient Funds';
                break;
            default:
                break;
        }
        return status
    };

    const columns = [
        {
            dataField: 'send_time',
            text: 'Send Time',
            sort: true,
            formatter: dateColumnFormatter
        },
        {
            dataField: 'sender',
            text: 'Sender',
            sort: true
        },
        {
            dataField: 'recipient',
            text: 'Recipient',
            sort: true
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: statusColumnFormatter
        },
        {
            dataField: 'price',
            text: 'Price',
            sort: true
        },
        {
            dataField: 'package_name',
            text: 'Tariff Plan',
            sort: true
        }
    ];

    const defaultSorted = [
        {
            dataField: 'send_time',
            order: 'desc'
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total"> Showing {from} to {to} of {size} Results</span>
    );

    const paginatorOptions = {
        hidePageListOnlyOnePage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: '50', value: 50
            },
            {
                text: '100', value: 100
            },
            {
                text: '200', value: 200
            },
            {
                text: '500', value: 500
            }
        ]
    };

    const noData = () => {
        return <p className='emptyTableMessage'>No matching records found</p>
    };

    return (
        <div className='content wideContent'>
            <div>
                <div className='pageTitle'>
                    <span>Individual SMS Report</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='myTable'>
                            <div className='tableHeader'>
                                <div className='dateTimePickerBlock'>
                                    <div className='col-6'>
                                        <label className='inputLabel'>From</label>
                                        <DatePicker
                                            selected={filterValues.date_from}
                                            onChange={(date) => {
                                                handleFilterValueChange(
                                                    'date_from',
                                                    new Date(date));
                                            }}
                                            selectsStart
                                            showTimeSelect
                                            dateFormat="yyyy-MM-dd H:mm"
                                            timeFormat='H:mm'
                                            maxDate={new Date()}
                                            minTime={startOfTheDay}
                                            maxTime={maxStartTime}
                                            className='formInput datePicker'
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <label className='inputLabel'>To</label>
                                        <DatePicker
                                            selected={filterValues.date_to}
                                            onChange={(date) => {
                                                handleFilterValueChange(
                                                    'date_to',
                                                    new Date(date));
                                            }}
                                            selectsEnd
                                            showTimeSelect
                                            dateFormat="yyyy-MM-dd H:mm"
                                            timeFormat='H:mm'
                                            minDate={filterValues.date_from}
                                            maxDate={new Date()}
                                            minTime={filterValues.date_from.getDate() < filterValues.date_to.getDate() ?
                                                startOfTheDay :
                                                filterValues.date_from
                                            }
                                            maxTime={maxEndTime}
                                            className='formInput datePicker'
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Select
                                        id='timeZones'
                                        name='timeZones'
                                        className='customSelect'
                                        classNamePrefix='select'
                                        options={timeZones}
                                        value={filterValues.time_zone}
                                        onChange={(selectedOption) => handleFilterValueChange(
                                            'time_zone',
                                            selectedOption
                                        )}
                                    />
                                </div>
                                <div className="mb-3 fewFilters">
                                    <div className='col-6'>
                                        <label
                                            className='inputLabel'
                                            htmlFor='sender'>Sender</label>
                                        <Select
                                            id='sender'
                                            name='sender'
                                            className='customSelect'
                                            classNamePrefix='select'
                                            options={senders}
                                            placeholder='Select sender'
                                            value={filterValues.sender}
                                            onChange={(selectedOption) => handleFilterValueChange(
                                                'sender',
                                                selectedOption
                                            )} />
                                    </div>
                                    <div className='col-6'>
                                        <label
                                            className='inputLabel'
                                            htmlFor='receiver'>Recipient</label>
                                        <input
                                            id='receiver'
                                            name='receiver'
                                            className='searchInput'
                                            placeholder='Search...'
                                            onChange={(event) => handleFilterValueChange(
                                                'recipient',
                                                event.target.value
                                            )} />
                                    </div>
                                </div>
                            </div>
                            <BootstrapTable
                                keyField='id'
                                data={smsReport}
                                columns={columns}
                                wrapperClasses='table-responsive'
                                bordered={false}
                                pagination={paginationFactory(paginatorOptions)}
                                defaultSorted={defaultSorted}
                                noDataIndication={noData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}