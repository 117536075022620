import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { FormData } from '../../helpers/FormData';
import { _login } from '../../services/apiConnections.service';

export const Login = (props) => {
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        email: '',
        password: ''
    });
    const [error, setError] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let result = await _login(formValues);
        if (result.error) {
            setError(result.error);
        } else {
            let token = result.token;
            let user = result.user;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            props.navigate('/dashboard');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='loginPageLogo'></div>
            {error && <p className='msgBlock errorMsg'>{error}</p>}
            <div className="mb-3">
                <label
                    className='loginLabel'
                    htmlFor='email'>Email</label>
                <input
                    id='email'
                    name='email'
                    type="email"
                    className="loginInput"
                    placeholder="Email"
                    value={formValues.email}
                    onChange={(event) => handleFormValueChange(
                        'email',
                        event.target.value
                    )}
                />
            </div>
            <div className="mb-3">
                <label
                    className='loginLabel'
                    htmlFor='password'>Password</label>
                <input
                    id='password'
                    name='password'
                    type="password"
                    className="loginInput"
                    placeholder="Password"
                    value={formValues.password}
                    onChange={(event) => handleFormValueChange(
                        'password',
                        event.target.value
                    )}
                />
            </div>
            <div className="btnWrapper">
                <button type="submit" className="btn mainBtn loginBtn">Log In</button>
            </div>
            <p className="loginLinkText">
                <Link className='loginLink' to={'/forgot-password'}>Forgot Your Password?</Link>
            </p>
            <p className="loginLinkText">
                Have no registration? <Link className='loginLink' to={'/sign-up'}>Sign Up</Link>
            </p>
        </form>
    );
}