import React from 'react';

export const PrivacyPolicy = () => {
    return (
        <div>
            <p>
                Please, read the following information carefully in order to understand Speedflow views and practices regarding your personal data and their application.
            </p>
            <p>
                The Policy of our company completely corresponds to Regulations (EU) 2016/679 of the European Parliament and Council of April 27, 2016 on the protection of natural persons concerning the processing of their personal data and the free movement of such data (GDPR) which became effective on May 25, 2018 and is applied to all of the European Union members.
            </p>
            <p>
                On 4 June 2021, the European Commission issued modernised standard contractual clauses under the GDPR for data transfers from controllers or processors in the EU/EEA (or otherwise subject to the GDPR) to controllers or processors established outside the EU/EEA (and not subject to the GDPR), which is also followed by Speedflow in its activities and included to our Privacy Policy.
            </p>
            <p>
                This Privacy Policy describes the types of personal information we collect, how we use personal information, to whom we can disclose it and the rights you have concerning the processing of this information.
            </p>
            <p>
                The Privacy Policy also describes the measures which we take to protect the personal information we receive and also the ways in which you can contact us in case you have any questions regarding the Privacy Policy.
            </p>
            <p>
                Speedflow treats seriously and attaches great importance to the correct and legal processing of all personal data, and respects the rights and confidentiality of our clients and subcontractors. Thus, this Policy establishes all company procedures which must be followed in the event of data handling for marketing purposes.
            </p>
            <p>
                Speedflow tries to protect and respect your private life. We safeguard your information and we use reasonable measures for preserving the integrity and security of all information obtained from our website visitors.
            </p>
            <p>
                <b>
                    Collection and storage of personal data
                </b>
                <br />
                Personal data is any information that identifies you. You can look through our website pages freely, without being obliged to fill in any registration forms or to enter any personal data.
            </p>
            <p>
                If you share with us your personal data (email and/or contact phone number, name, company, country, Skype ID, etc.) when filling in the forms on our website and/or by sending a request, then we can provide you with marketing materials and special offers from the name of Speedflow.
            </p>
            <p>
                To the extent permitted by applicable law, we typically store the personal information you have provided us for the duration of our mutual partnership and/or business relations or for one year because we want to make sure that your user experience will be immaculate in case of re-installation.
            </p>
            <p>
                As described in the “Rights of data subjects” section below, to the extent provided by law, you may request that we delete your personal information or change the processing of such information by contacting us at info@nexamsg.com
            </p>
            <p>
                <b>
                    Use of collected information
                </b>
                <br />
                We use your personal data to send you commercial proposals, inform you about special offers, and other useful information, related to our products and services.
                The information we collect is securely stored and used only for internal purposes, is not transmitted and will not be transferred or sold to other organizations or third parties or for services on a commercial basis. Please note that we collect the data necessary to perform the desired service, and in this process, we use only the data that we received directly from you.
                Speedflow as the site owner may disclose personal information only in cases required by law.
            </p>
            <p>
                <b>
                    Data security
                </b>
                <br />
                Our main asset is to guarantee our users a reasonable level of personal data security. This applies to all personal data provided in a special form on our website.
                We are responsible for ensuring our marketing is in accordance with this Policy and all data protection rules and regulations. Our company complies with the GDPR terms and other legislation related to the Data Protection.
            </p>
            <p>
                <b>
                    Rights of data subjects
                </b>
                <br />
                You can always contact us if you have any questions regarding the use of your personal data, their deletion, change or cancelling your email subscription.
            </p>
            <p>
                <b>
                    Links to other sites
                </b>
                <br />
                This Privacy Policy applies to objects owned by Speedflow and to no one else. The pages and websites owned by the company may contain links to other websites that may not have policies to protect the personal data of their users and customers.
            </p>
            <p>
                <b>
                    Changes to our Privacy Policy
                </b>
                <br />
                All the information on this website is reliable at the time of its publication and can be changed over time. Any changes made in our Privacy Policy in the future can be placed on this page without prior notice. Speedflow advises that you check this page regularly to keep yourself informed about any updates.
            </p>
            <p>
                <b>
                    Contact us
                </b>
                <br />
                Please, do not hesitate to contact us if you have any question connected with this Privacy Policy. Send your requests regarding personal data processing, deletion, changes, etc. to – GDPR@speedflow.com.
            </p>
            <p>
                We are working within the framework of the GDPR and other legislation related to the Data Protection, the full text of which you can at the official EU government sites
            </p>
        </div >
    )
};