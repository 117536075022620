import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FormData } from '../../helpers/FormData';
import { _contactCreate, _contactEdit, _contactGetById } from '../../services/apiConnections.service';
import { ExtraField } from '../elements/ExtraField.component';

export const ContactUpsert = (props) => {

    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        name: '',
        number: '',
    });
    const [formErrors, setFormErrors] = useState({});
    const [toggle, setToggle] = useState(false);
    const [extraFields, setExtraFields] = useState([]);

    const inputRef = useRef();

    let navigate = useNavigate();
    let params = useParams();

    const maxExtraFields = 10;

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        if (props.edit && token) {
            handleContactGet(token);
        }
    }, [token, props]);

    const handleContactGet = async (token) => {
        let result = await _contactGetById(token, params.id);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            console.log("--result:", result);
            setFormValues({
                'name': result.name,
                'number': result.number,
                'fields': result.fields
            });
            setExtraFields(result.fields);
        }
        setError(result.error);
    };

    const handleUpsert = async (event) => {
        event.preventDefault();
        let result;

        if (!props.edit)
            result = await _contactCreate(token, formValues);
        else
            result = await _contactEdit(token, formValues, params.id);

        if (result.error) {
            setError(result.error);
        } else {
            console.log("success");
            navigate('/contacts');
        }
    };

    const handleValidation = (target) => {
        switch (target.name) {
            case 'name':
                // name validation
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        name: "Cannot Be Empty"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['name'];
                    setFormErrors(copy);
                }
                break;
            case 'number':
                // content validation
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        number: "Cannot Be Empty"
                    }));
                } else if (!/^\+?\d*$/.test(target.value)) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        number: "Only '+' And Digits Are Available"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['number'];
                    setFormErrors(copy);
                }
                break;

            default: break;
        }
    };

    const formIsValid = () => {
        return !Object.keys(formErrors).length && formValues.name.length && formValues.number.length;
    };

    const handleExtraFieldChange = (e, index) => {
        const values = [...extraFields];
        values[index].value = e.target.value;
        setExtraFields(values);
        setFormValues({
            ...formValues,
            fields: extraFields
        });
    };

    const handleAddExtraField = (event) => {
        event.preventDefault();
        let copy = { ...formErrors };
        delete copy['extraField'];
        setFormErrors(copy);
        const values = [...extraFields];
        if (!inputRef.current.value) {
            setFormErrors(formErrors => ({
                ...formErrors,
                extraField: `Cannot Be Empty`
            }));
            return;
        }
        for (let i of values) {
            if (i.key === inputRef.current.value) {
                setFormErrors(formErrors => ({
                    ...formErrors,
                    extraField: `Field ${i.key} Already Exist`
                }));
                return;
            }
        }
        values.push({
            key: inputRef.current.value,
            value: "",
        });
        setExtraFields(values);
        setToggle(false);
    };

    const handleDeleteField = (e, index) => {
        const values = [...extraFields];
        values.splice(index, 1);
        setExtraFields(values);
        setFormValues({
            ...formValues,
            fields: values
        });
    };

    const addBtnClick = (event) => {
        event.preventDefault();
        if (extraFields.length < maxExtraFields) {
            setToggle(true);
        }
    };

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>{!props.edit ? 'Create Contact' : 'Edit Contact'}</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='pageContent'>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='name'>Contact Name</label>
                        <div className='inputWrapper'>
                            <input
                                id='name'
                                name='name'
                                type="text"
                                className={`formInput ${formErrors.name ? 'invalidInput' : ''}`}
                                placeholder="Name Your Contact"
                                value={formValues.name}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'name',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.name}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='number'>Phone</label>
                        <div className='inputWrapper'>
                            <input
                                id='number'
                                name='number'
                                type="text"
                                className={`formInput ${formErrors.number ? 'invalidInput' : ''}`}
                                placeholder="Phone Number"
                                value={formValues.number}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'number',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.number}</span>
                        </div>
                    </div>
                    <div className='subtitle'>
                        <span>Extra Fields</span>
                    </div>
                    {extraFields.map((obj, index) => (
                        <ExtraField
                            key={index}
                            objValue={obj}
                            onChange={handleExtraFieldChange}
                            index={index}
                            deleteField={handleDeleteField}
                        />
                    ))}
                    {!toggle ? (
                        <div>
                            <button
                                className={`extraFieldsBtn btn ${extraFields.length === maxExtraFields && 'disabledBtn'}`}
                                onClick={addBtnClick}
                                disabled={extraFields.length === maxExtraFields}>+</button>
                        </div>
                    ) : (
                        <div className="extraFieldDialog">
                            <input
                                className={`formInput shortInput ${formErrors.extraField ? 'invalidInput' : ''}`}
                                type="text"
                                placeholder="Name New Field"
                                ref={inputRef} />
                            <button
                                className="extraFieldsBtn btn"
                                onClick={handleAddExtraField}>+</button>
                            <span className='fieldError'>{formErrors.extraField}</span>
                        </div>
                    )}
                    <div className='buttonBlock'>
                        <button
                            onClick={handleUpsert}
                            className={`mainBtn btn ${formIsValid() ? '' : 'disabledBtn'}`}
                            disabled={!formIsValid()}>{props.edit ? 'Save' : 'Create'}</button>
                    </div>
                </div>
            </div>
        </div >
    );
};