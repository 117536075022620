import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { AuthLayouts } from './components/elements/AuthLayouts.component';
import { RequireAuth } from './components/elements/RequireAuth.component';
import { MainPage } from './components/elements/MainPage.component';

import { Login } from './components/pages/login.component';
import { SignUp } from './components/pages/signup.component';
import { Dashboard } from './components/pages/dashboard.component';
import { SendSms } from './components/pages/sendSms.component';
import { Campaigns } from './components/pages/campaigns.component';
import { CampaignCreate } from './components/pages/campaignCreate.component';
import { CampaignView } from './components/pages/campaignView.component';
import { NotFound } from './components/pages/notFound.component';
import { SmsTemplates } from './components/pages/smsTemplates.component';
import { SmsTemplateUpsert } from './components/pages/smsTemplateUpsert.component';
import { Contacts } from './components/pages/contacts.component';
import { ContactUpsert } from './components/pages/contactUpsert.component';
import { Prices } from './components/pages/prices.component';
import { IndividualSmsReport } from './components/pages/reports/individualSmsReport.component';
import { CampaignSmsReport } from './components/pages/reports/campaignSmsReport.component';
import { CampaignEdit } from './components/pages/campaignEdit.component';
import { Support } from './components/pages/support.component';
import { Profile } from './components/pages/profile.component';
import { Cancel } from './components/pages/cancel.component';
import { Success } from './components/pages/success.component';
import { ForgotYourPassword } from './components/pages/forgotYourPasswrod.component';
import { ResetPassword } from './components/pages/resetPassword.component';


const App = () => {

  let token = localStorage.getItem('token');
  let navigate = useNavigate();

  return (
    <div className="App">
      <Routes>
        <Route path='*' element={<AuthLayouts />}>
          <Route path="log-in" element={<Login navigate={navigate} />} />
          <Route path="sign-up" element={<SignUp navigate={navigate} />} />
          <Route path="forgot-password" element={<ForgotYourPassword />} />
          <Route path="reset-password" element={<ResetPassword navigate={navigate} />} />
        </Route>
        <Route path='/'
          element={
            <RequireAuth token={token}>
              <MainPage navigate={navigate} />
            </RequireAuth>
          }>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="prices" element={<Prices />} />
          <Route path="send-sms" element={<SendSms />} />
          <Route path="support" element={<Support />} />
          <Route path="profile" element={<Profile />} />
          <Route path="campaigns" element={<Campaigns />} />
          <Route path="create-campaign" element={<CampaignCreate navigate={navigate} />} />
          <Route path="campaigns/edit-campaign/:id" element={<CampaignEdit navigate={navigate}/>}  />
          <Route path="campaigns/:id" element={<CampaignView />} />
          <Route path="sms-templates" element={<SmsTemplates />} />
          <Route path="create-template" element={<SmsTemplateUpsert navigate={navigate} />} />
          <Route path="sms-templates/:id" element={<SmsTemplateUpsert navigate={navigate} edit />} />
          <Route path="campaigns/:id" element={<CampaignView />} />
          <Route path="campaign-sms-report" element={<CampaignSmsReport />} />
          <Route path="individual-sms-report" element={<IndividualSmsReport />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="create-contact" element={<ContactUpsert />} />
          <Route path="contacts/:id" element={<ContactUpsert edit />} />
          <Route path="cancel" element={<Cancel />} />
          <Route path="success" element={<Success />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
