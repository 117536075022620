import React, { useEffect, useState } from 'react';

import { _setNewPassword } from '../../services/apiConnections.service';

export const ResetPassword = (props) => {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [token, setToken] = useState('');
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        if (!token) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            setToken(urlParams.get('token'));
            console.log(token);
        }
    }, [token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await _setNewPassword(token, password);
        props.navigate('/log-in');
    };

    const handleValidation = (target) => {
        switch (target.name) {
            case 'password':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        password: "Password Is Required"
                    }));
                } else if (target.value && target.value != confirmPassword) {
                    let copy = { ...formErrors };
                    delete copy['password'];
                    setFormErrors(copy);
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        confirmPassword: "Must Match Your Password"
                    }));
                } else if (target.value && target.value == confirmPassword) {
                    let copy = { ...formErrors };
                    delete copy['confirmPassword'];
                    delete copy['password'];
                    setFormErrors(copy);
                }
                else {
                    let copy = { ...formErrors };
                    delete copy['password'];
                    setFormErrors(copy);
                }
                break;
            case 'confirm_password':
                if (target.value != password) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        confirmPassword: "Must Match Your Password"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['confirmPassword'];
                    setFormErrors(copy);
                }
                break;
            default: break;
        }
    };

    const formIsValid = () => {
        return !Object.keys(formErrors).length && password && confirmPassword;
    };

    return (
        <div className='forgotPasswordFormWrapper'>
            <form onSubmit={handleSubmit}>
                <div className='loginPageLogo'></div>
                <div className="mb-3">
                    <label
                        className='loginLabel'
                        htmlFor='password'>Password</label>
                    <div className='inputWrapper'>
                        <input
                            id='password'
                            name='password'
                            type="password"
                            className={`loginInput ${formErrors.password ? 'invalidInput' : ''}`}
                            placeholder="Password"
                            required
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            onKeyUp={(event) => { handleValidation(event.target) }}
                        />
                        <span className='fieldError'>{formErrors.password}</span>
                    </div>
                </div>
                <div className="mb-3">
                    <label
                        className='loginLabel'
                        htmlFor='confirm_password'>Confirm Password</label>
                    <div className='inputWrapper'>
                        <input
                            id='confirm_password'
                            name='confirm_password'
                            type="password"
                            value={confirmPassword}
                            className={`loginInput ${formErrors.confirmPassword ? 'invalidInput' : ''}`}
                            placeholder="Confirm Password"
                            onChange={(event) => setConfirmPassword(event.target.value)}
                            onKeyUp={(event) => { handleValidation(event.target) }}
                        />
                        <span className='fieldError'>{formErrors.confirmPassword}</span>
                    </div>
                </div>
                <div className="btnWrapper">
                    <button
                        type="submit"
                        className="btn loginBtn mainBtn"
                        disabled={!formIsValid()}>Set New Password</button>
                </div>
            </form>
        </div>
    );
};