import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast, Toaster } from 'react-hot-toast';
import DatePicker from 'react-datepicker';
import { useCSVReader } from 'react-papaparse';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";

import { FormData } from '../../helpers/FormData';
import { _campaignUpsert, _campaignDelete, _campaignGetById, _contactsGet, _sendersGet, _templatesGet, _getTimeZones, _checkCampaignNameIsUnique } from '../../services/apiConnections.service';
import { SmsCalculator } from '../elements/smsCalculator.component';
import { TariffPlanTile } from '../elements/TariffPlanTile.component';

export const CampaignCreate = (props) => {

    const { CSVReader } = useCSVReader();

    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);
    const [stepNumber, setStepNumber] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        campaign_name: '',
        sender: null,
        template: null,
        message_text: '',
        receivers: [],
        numbers: [],
        schedule: {
            label: 'Send Now',
            value: 0
        },
        time_zone: {
            label: 'default',
            value: 99999999
        },
        start_date: ''
    });
    const [numbers, setNumbers] = useState([]);
    const [senders, setSenders] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [templateVars, setTemplateVars] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [campaignInfo, setCampaignInfo] = useState({
        id: '',
        price: '',
        orders_used: 0,
        messages_count: 0,
        suggestions: [],
        tariffs: []
    });
    const [startDate, setStartDate] = useState(
        new Date(
            new Date(
                new Date().setHours(
                    new Date().getHours() + 1
                )
            ).setMinutes(0)
        )
    );
    const [prevStartDate, setPrevStartDate] = useState(startDate);
    const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [minTime, setMinTime] = useState(new Date());

    const [showModalBuyTariff, setShowModalBuyTariff] = useState(false);

    const [havePackage, setHavePackage] = useState(false);
    const [availablePackage, setAvailablePackage] = useState(false);
    const [overLimit, setOverLimit] = useState(false);

    let missingVars = {};
    let schedule = [
        {
            label: 'Send Now',
            value: 0
        },
        {
            label: 'Send Later',
            value: 1
        }
    ];

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        if (token) {
            handleSenders();
            handleTemplates();
            handleContacts();
            handleTimeZones();
        }
    }, [token]);

    const handleSenders = async () => {
        let tmpSenders = await _sendersGet(token);
        let arr = tmpSenders.senders.map((e) => {
            return {
                label: e.number,
                value: e.id
            }
        });
        setSenders(arr);
    };

    const handleTemplates = async () => {
        let result = await _templatesGet(token);
        let arr = result.sms_templates.map((e) => {
            return {
                label: e.name,
                value: e.ID,
                content: e.content
            }
        });
        setTemplates(arr);
    };

    const handleContacts = async () => {
        let result = await _contactsGet(token);
        let arr = result.Contacts.map((e) => {
            return {
                label: e.name,
                value: e.ID,
                fields: e.fields,
                number: e.number
            }
        });
        setContacts(arr);
    };

    const handleTimeZones = async () => {
        let result = await _getTimeZones(token);
        let arr = result.time_zones.map((e) => {
            return {
                label: e.name,
                value: result.time_zones.indexOf(e)
            }
        });
        setTimeZones(arr);
    };

    useEffect(() => {
        let tz = timeZones.filter(x => x.label === selectedTimezone);
        handleFormValueChange('time_zone', tz[0]);
    }, [timeZones])

    useEffect(() => {
        if (formValues.template) {
            handleFormValueChange(
                'message_text',
                formValues.template.content
            );
        }
    }, [formValues.template]);

    useEffect(() => {
        if (numbers.length) {
            handleFormValueChange(
                'numbers',
                numbers
            );
        }
    }, [numbers]);

    useEffect(() => {
        checkVars();
    }, [formValues.message_text, formValues.receivers, stepNumber]);

    const checkVars = () => {
        setWarning('');
        if (stepNumber === 1) {
            let vars = extractTemplateVars(formValues.message_text);
            if (vars.length) {
                setWarning('Message length depends on variables');
                setTemplateVars(vars);
            }
        } else if (stepNumber === 2) {
            if (templateVars.length) {
                for (let receiver of formValues.receivers) {
                    let tmpVars = templateVars;
                    for (let varKey of receiver.fields) {
                        for (let variable of templateVars) {
                            if (variable === varKey.key) {
                                tmpVars = tmpVars.filter((n) => { return n !== variable });
                            }
                        }
                    }
                    if (tmpVars.length) {
                        missingVars[receiver.label] = tmpVars;
                    }
                }
                if (Object.keys(missingVars).length !== 0) {
                    console.log(missingVars);
                    setWarning('Some of the variables are missing in selected recipients');
                }
            }
        }
    };

    const extractTemplateVars = (template) => {
        const regex = /\{\{([0-9a-zA-Z_]*?)\}\}/g;

        let match, matches = [];
        while ((match = regex.exec(template)) !== null) {
            matches.push(match[1]);
        }
        return matches;
    };

    const handleCreate = async (event, draft = false) => {
        event.preventDefault();

        if (draft) {
            let result = await _campaignUpsert(token, formValues);
            if (result.error) {
                console.log("error");
                setError(result.error);
            } else {
                let getResult = await _campaignGetById(token, result.ID);
                if (getResult.error) {
                    setError(getResult.error);
                } else {
                    setCampaignInfo({
                        id: getResult.ID,
                        price: getResult.price,
                        orders_used: getResult.orders_used,
                        messages_count: getResult.messages_count,
                        suggestions: getResult.suggestions,
                        tariffs: getResult.orders_usage
                    });
                    setHavePackage(getResult.orders_usage.length !== 0);
                    setAvailablePackage(getResult.suggestions.length !== 0);
                    setOverLimit(getResult.messages_count > getResult.orders_used);
                    changeStep(true);
                }
            }
        } else {
            let result = await _campaignUpsert(token, formValues, campaignInfo.id);
            if (result.error) {
                console.log(`error: ${result.error}`);
                setError(result.error);
            } else {
                props.navigate('/campaigns');
            }
        }
    };

    const handleSaveAsDraft = () => {
        props.navigate('/campaigns');
    };

    const deleteDraft = async () => {
        await _campaignDelete(token, campaignInfo.id);
    };

    const changeStep = (isNext) => {
        setStepNumber(stepNumber + (isNext ? 1 : -1));
    };

    const hadleNumbersUploads = (uploadedNumbers) => {
        let result = [];
        let data = uploadedNumbers.data;
        let keys = data[0];
        for (let line = 1; line < data.length; line++) {
            let recipient = {};
            let fields = [];
            for (let cell = 0; cell < data[line].length; cell++) {
                let additionalField = {};
                if (data[line][cell].length) {
                    if (cell === 0) {
                        recipient[keys[cell]] = data[line][cell];
                    } else {
                        additionalField.key = keys[cell];
                        additionalField.value = extractTemplateVars(data[line][cell])[0];
                        fields.push(additionalField);
                    }
                }
            }
            if (fields.length) {
                recipient.fields = fields;
            }
            if (Object.keys(recipient).length !== 0) {
                result.push(recipient);
            }
        }
        setNumbers(result);
    };

    const handleInfoUpdate = (tariffName) => {
        setShowModalBuyTariff(false);
        toast.success(`Congratulations, the tariff ${tariffName} has been purchased`);
    };

    const firstStepIsValid = () => {
        return formValues.campaign_name.length && formValues.sender && formValues.message_text.length;
    };

    const changeStepToSecond = async () => {
        let response = await _checkCampaignNameIsUnique(token, formValues.campaign_name);
        if (response === true) {
            changeStep(true);
            setError(null);
        } else {
            setError(response.error);
        }
    };

    const secondStepIsValid = () => {
        return (formValues.receivers.length || numbers.length) && !error
    };

    const thirdStepIsValid = () => {
        return true;
    };

    const dateToLocalISO = (date) => {
        let timeZoneOffset = (new Date(date)).getTimezoneOffset() * 60000;
        let localISOTime = (new Date(date - timeZoneOffset)).toISOString();
        return localISOTime;
    };

    useEffect(() => {
        let now = new Date();
        if (startDate.getTime() > now.getTime()) {
            setMinTime(now.setHours(0));
        } else {
            setMinTime(now);
            if (prevStartDate.getDate() !== startDate.getDate()) {
                setStartDate(new Date(
                    new Date(
                        new Date().setHours(
                            new Date().getHours() + 1
                        )
                    ).setMinutes(0)
                ));
            }
        }
        handleFormValueChange('start_date', dateToLocalISO(startDate));
    }, [startDate]);

    useEffect(() => {
        if (formValues.schedule.value === 0) {
            handleFormValueChange('start_date', dateToLocalISO(new Date()));
        } else {
            handleFormValueChange('start_date', dateToLocalISO(startDate));
        }
    }, [formValues.schedule]);

    const renderSwitch = (step) => {
        switch (step) {
            case 1:
                return (
                    <div className='step'>
                        <div className='stepTitle'>Step 1</div>
                        <div className='stepBody'>
                            <div className="mb-3">
                                <label
                                    className='inputLabel requiredInput'
                                    htmlFor='campaignName'>Campaign Name</label>
                                <input
                                    id='campaignName'
                                    name='campaignName'
                                    type="text"
                                    className="wizardInput"
                                    placeholder="Name Your Campaign"
                                    value={formValues.campaign_name}
                                    onChange={(event) => handleFormValueChange(
                                        'campaign_name',
                                        event.target.value
                                    )}
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    className='inputLabel requiredInput'
                                    htmlFor='sender'>Sender</label>
                                <Select
                                    id='sender'
                                    name='sender'
                                    className='customSelect'
                                    classNamePrefix='select'
                                    placeholder='Select Sender'
                                    options={senders}
                                    value={formValues.sender}
                                    onChange={(selectedOption) => handleFormValueChange(
                                        'sender',
                                        selectedOption
                                    )} />
                            </div>
                            <div className="mb-3">
                                <label
                                    className='inputLabel'
                                    htmlFor='template'>SMS Template</label>
                                <Select
                                    id='template'
                                    name='template'
                                    className='customSelect'
                                    classNamePrefix='select'
                                    placeholder='Select Template'
                                    options={templates}
                                    value={formValues.template}
                                    onChange={(selectedOption) => {
                                        handleFormValueChange(
                                            'template',
                                            selectedOption
                                        );
                                    }} />
                            </div>
                            <SmsCalculator
                                defaultEncoder='7bit'
                                value={formValues.message_text}
                                warning={warning}
                                onChange={(event) => handleFormValueChange(
                                    'message_text',
                                    event.target.value
                                )} />
                            <div className='wizardButtons'>
                                <button
                                    onClick={() => changeStepToSecond()}
                                    className={`btn mainBtn next ${!firstStepIsValid() && 'disabledBtn'}`}
                                    disabled={!firstStepIsValid()}>Next</button>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className='step'>
                        <div className='stepTitle'>Step 2</div>
                        <div className='stepBody'>
                            {warning && <p className='msgBlock warningMsg'>{warning}</p>}
                            <div className="mb-3">
                                <label
                                    className='inputLabel requiredInput'
                                    htmlFor='contacts'>Recipients</label>
                                <Select
                                    id='contacts'
                                    name='contacts'
                                    className='customSelect'
                                    classNamePrefix='select'
                                    options={contacts}
                                    value={formValues.receivers}
                                    placeholder='Select Recipients'
                                    isMulti
                                    onChange={(selectedOption) => handleFormValueChange(
                                        'receivers',
                                        selectedOption
                                    )} />
                            </div>
                            <div className="mb-3">
                                <label
                                    className='inputLabel'
                                    htmlFor='numbers'>Numbers</label>
                                <CSVReader
                                    onUploadAccepted={(results) => { hadleNumbersUploads(results) }}>
                                    {
                                        (
                                            { getRootProps,
                                                acceptedFile,
                                                ProgressBar,
                                                getRemoveFileProps }
                                        ) => (
                                            <div>
                                                <div className='fileUploadWrapper'>
                                                    <button type='button' {...getRootProps()} className="browseFileBtn btn">Browse File</button>
                                                    <div className='fileName'>
                                                        <span>{acceptedFile && acceptedFile.name}</span>
                                                    </div>
                                                    {acceptedFile &&
                                                        <button {...getRemoveFileProps()} className='removeFileBtn btn'>Remove</button>
                                                    }
                                                </div>
                                                <ProgressBar />
                                            </div>
                                        )
                                    }
                                </CSVReader>
                            </div>
                            <div className='wizardButtons'>
                                <button onClick={() => changeStep(false)} className='btn mainBtn back'>Back</button>
                                <button
                                    onClick={() => { changeStep(true); }}
                                    className={`btn mainBtn next ${!secondStepIsValid() && 'disabledBtn'}`}
                                    disabled={!secondStepIsValid()}>Next</button>
                            </div>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className='step'>
                        <div className='stepTitle'>Step 3</div>
                        <div className='stepBody'>
                            <div className="mb-3">
                                <label
                                    className='inputLabel'
                                    htmlFor='schedule'>SMS Schedule</label>
                                <Select
                                    id='schedule'
                                    name='schedule'
                                    className='customSelect'
                                    classNamePrefix='select'
                                    options={schedule}
                                    value={formValues.schedule}
                                    onChange={(selectedOption) => handleFormValueChange(
                                        'schedule',
                                        selectedOption
                                    )} />
                            </div>
                            {formValues.schedule.value === 1 &&
                                <div className='mb-3'>
                                    <div className='dateTimePickerBlock'>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => {
                                                setPrevStartDate(startDate);
                                                setStartDate(new Date(date));
                                            }}
                                            minDate={new Date()}
                                            dateFormat="yyyy-MM-dd"
                                            className='formInput datePicker'
                                        />
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => {
                                                setPrevStartDate(startDate);
                                                setStartDate(new Date(date));
                                            }}
                                            minTime={minTime}
                                            maxTime={new Date().setHours(23, 59, 59)}
                                            className='formInput'
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            timeCaption="Time"
                                            dateFormat="H:mm"
                                            timeFormat='H:mm'
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Select
                                            id='timeZones'
                                            name='timeZones'
                                            className='customSelect'
                                            classNamePrefix='select'
                                            options={timeZones}
                                            value={formValues.time_zone}
                                            onChange={(selectedOption) => handleFormValueChange(
                                                'time_zone',
                                                selectedOption
                                            )} />
                                    </div>
                                </div>
                            }
                            <div className='wizardButtons'>
                                <button onClick={() => changeStep(false)} className='btn mainBtn back'>Back</button>
                                <button
                                    onClick={(event) => {
                                        handleCreate(event, true)
                                    }}
                                    className={`btn mainBtn next ${!thirdStepIsValid() && 'disabledBtn'}`}
                                    disabled={!thirdStepIsValid()}>Create</button>
                            </div>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className='step finalStep'>
                        <div className='stepTitle'>Step 4</div>
                        <div className='stepBody'>
                            <p className='wizardText'>{`Number of SMS to be sent: ${campaignInfo.messages_count}`}</p>
                            {!havePackage &&
                                <p className='wizardText'>{`Campaign cost will be ${campaignInfo.price}. Current prices will be applied.`}</p>
                            }
                            {havePackage &&
                                <div>
                                    <p className='wizardText'>{`Your Tariff Plan "${campaignInfo.tariffs[0].name}" is valid till ${new Date(campaignInfo.tariffs[0].end_date).toLocaleDateString()} and you have ${campaignInfo.orders_used}.`}</p>
                                    {!overLimit &&
                                        <p className='wizardText'>{`In case of over limit current SMS prices will be applied.`}</p>
                                    }
                                    {overLimit &&
                                        <p className='wizardText'>{`You have ${campaignInfo.messages_count - campaignInfo.orders_used}. Campaign cost will be ${campaignInfo.price}. In case of over limit current SMS prices will be applied.`}</p>
                                    }
                                </div>
                            }
                            {availablePackage &&
                                <p className='wizardText'>You can purchase Tariff Plan using following option <button className='tariffPlansBtn' onClick={() => setShowModalBuyTariff(true)}>Tariff Plan</button></p>
                            }
                            <div className='wizardButtons'>
                                <div className='subBlock'>
                                    <button onClick={() => {
                                        changeStep(false);
                                        deleteDraft();
                                    }} className='btn mainBtn back'>Edit</button>
                                    <button onClick={handleSaveAsDraft} className='btn mainBtn'>Save As Draft</button>
                                    <button onClick={handleCreate} className='btn mainBtn'>Activate Campaign</button>
                                </div>
                                <button onClick={() => {
                                    deleteDraft();
                                    props.navigate('/campaigns');
                                }} className='btn mainBtn delete'>Delete</button>
                            </div>
                        </div>
                        <Modal
                            className='tariffPlansModal'
                            show={showModalBuyTariff}
                            onHide={() => setShowModalBuyTariff(false)}
                            backdrop={true}
                            keyboard={false}
                            centered>
                            {campaignInfo.suggestions.length > 0 &&
                                campaignInfo.suggestions.map((tariff) => {
                                    return (
                                        <TariffPlanTile
                                            key={tariff.ID}
                                            tariff={tariff}
                                            token={token}
                                            onBuy={handleInfoUpdate} />
                                    )
                                })
                            }
                        </Modal>
                        <Toaster />
                    </div>
                );
            default:
                return (
                    <div className='step'>
                        <p className='msgBlock errorMsg'>Something went wrong</p>
                    </div>
                )
        }
    };

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>Create Campaign</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                {renderSwitch(stepNumber)}
            </div>
        </div >
    );
};