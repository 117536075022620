import React, { useState } from "react";
import { toast } from 'react-hot-toast';

import { _buyTariff } from "../../services/apiConnections.service";
import { ModalDialog } from "./ModalDialog.component";

export const TariffPlanTile = (props) => {

    const [showBuyDialog, setShowBuyDialog] = useState(false);

    let tariff = props.tariff;
    let now = new Date();

    const handleTariffBuy = async () => {
        let result = await _buyTariff(props.token, tariff.ID);
        if (result.error) {
            console.log("--error:", result.error);
            toast.error(`The purchase cannot be completed. Contact the administrator.`);

        } else {
            window.location.href = result.url;
            // props.onBuy(tariff.name);
        }
        setShowBuyDialog(false)
    };

    return (
        <div className='tariffPlanTile'>
            <div className='tariffPlanHeader'>
                <div className='tariffPlanName'>
                    <p>{tariff.name}</p>
                </div>
                {!props.bought &&
                    <div className='tariffPlanPrice'>
                        <p>{tariff.cost} $</p>
                    </div>
                }
            </div>
            <div className='tariffPlanBody'>
                <p>Validity Time: {tariff.validity_period || ((new Date(tariff.end_date).getTime() - now.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0)} days</p>
                <p>SMS Limit: {tariff.sms_limit}</p>
                <p>Description: {tariff.description}</p>
            </div>
            {!props.bought &&
                <button
                    onClick={() => setShowBuyDialog(true)}
                    className='btn mainBtn'>Buy</button>
            }
            <ModalDialog
                show={showBuyDialog}
                onHide={() => setShowBuyDialog(false)}
                onAccept={async () => handleTariffBuy()}
                title={`Buy Tariff ${tariff.name}`}
                acceptBtnText="Buy">
                <span>{`Are you sure you want to buy tariff '${tariff.name}'?`}</span>
            </ModalDialog>
        </div >
    );
}; 