import React from "react";

export const ExtraField = ({ objValue, onChange, index, deleteField }) => {
    const { key, value } = objValue;
    return (
        <div className="mb-3">
            <label
                className='inputLabel'
                htmlFor={key}>{key}</label>
            <div className="extraFieldDialog">
                <input
                    className='formInput'
                    type='text'
                    placeholder={`Enter ${key}`}
                    id={key}
                    value={value}
                    onChange={(e) => {
                        onChange(e, index)
                    }}
                />
                <button
                    className="extraFieldsBtn btn deleteBtn"
                    onClick={(e) => deleteField(e, index)}>-</button>
            </div>
        </div>
    );
};