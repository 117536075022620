import React from 'react';

export const TermsAndConditions = () => {
    return (
        <div>
            <p>
                This General Terms and Conditions (“Terms and Conditions”) by and between the <b>Customer</b> and <b>Speedflow Communications Limited</b> (“Speedflow”) constitute a legal and binding agreement between Speedflow and the Customer and set forth the terms pursuant to which the Customer may access and/or use the Services, as defined. Speedflow and Customer may be individually referred to as a “Party”, and collectively as “Parties”.
            </p>
            <p>
                By clicking on the “Accept” button or checking the checkbox on the signup page when subscribing to the Services, Customer : (i) affirms that it has read, agreed and will comply with this Agreement; (ii) affirms that the Customer is at least eighteen (18) years of age.
            </p>
            <p>
                IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, YOU MUST NOT CLICK ACCEPT OR THE CHECKBOX ON THE SIGNUP PAGE.
            </p>
            <p>
                <b>1. Definitions</b>
            </p>
            <p>
                <b>"Applicable Law”</b> means any and all law, statute, regulation, rule, ordinance, treaty convention, and/or court or administrative order or ruling of any governing body with jurisdiction over the Service, the Customer, and/or the Customer’s use of the Services;
            </p>
            <p>
                <b>“Confidential Information”</b> shall include but is not limited to any and all information, data, ideas, marketing information, marketing methods, customer lists, financial information, and business strategies in any and all forms, formats, and mediums, in writing orally or through other means, whether identified as confidential or not that arises from or relates to a Party, its business, business plans, procedures, finances, owners, officers, employees or agents. A Party’s Confidential Information shall not include information that (a) is or becomes a part of the public through no act or omission of the other Party; (b) can be demonstrated was rightfully in the possession of the other Party or was known by it prior to its disclosure; (c) can be demonstrated is independently developed by the Receiving Party without the use of any Confidential Information of the other Party; (d) was or is provided by the Disclosing Party to third parties without restriction on disclosure, and (e) is required to be disclosed by law or by request of any government or regulatory authority with prior written notice to the other Party.
            </p>
            <p>
                <b>“Customer”</b>means individual accepting this Terms and Conditions on its own behalf.
            </p>
            <p>
                <b>“Customer Data”</b> means any data, content, or materials that the Customer uploads into, enters into, or submits to the Service including from Third-Party Integrations. For clarification, Customer Data excludes Usage Data.
            </p>
            <p>
                <b>"Documentation"</b> means Speedflow’s user manuals, handbooks, help articles and guides relating to the Service provided by Speedflow to Customer either electronically or in hard copy form.
            </p>
            <p>
                <b>“Effective Date”</b> means the date on which the Customer subscribes to the Service.
            </p>
            <p>
                <b>“Feedback”</b> means any suggestions or idea for modifying the Service or a portion of the Service;
            </p>
            <p>
                <b>“Service”</b> means providing access to the platform where the Customer is allowed to generate SMS traffic and sent to the recipients by using proposed options.
            </p>
            <p>
                <b>“Usage Data”</b> means the data regarding applications utilized with the Service, configurations, log data, and the performance results for the Service.
            </p>
            <p>
                <b>2. Services Offered</b>
            </p>
            <p>
                2.1 Speedflow provides online-based services that include the ability to send SMS in accordance with the Price and/or Tariff Plan chosen by the Customer.
            </p>
            <p>
                <b>3. Quality and Maintenance</b>
            </p>
            <p>
                3.1 <b>Minimum Disruptions.</b> Speedflow shall use reasonable endeavours to provide the Service with minimum disruptions. Speedflow cannot guarantee that the Service will always function without any disruptions, interruptions or delays. Speedflow does not warrant full availability of any type.
            </p>
            <p>
                3.2 <b>Support Services.</b> Speedflow will provide standard support services to resolve technical issues with, and answers queries regarding the use of the Service for purchased Service at no additional cost.
            </p>
            <p>
                3.3 <b>Upgrades.</b> Speedflow may change technical features in order to keep pace with the latest demands and technological developments or to comply with any Applicable Laws without obtaining the Customer’s written pre-approval. Speedflow may also have to repair, improve, and/or upgrade, provide patches, enhancements, or fixes for the Service and this may require us to restrict, limit, suspend, interfere and/or interrupt the Service at any time in our sole discretion including in the event of an emergency. Speedflow shall endeavor to provide the Customer with advance notice where practicable and carry out such maintenance, where possible, outside of regular business hours.
            </p>
            <p>
                3.4 <b>Login Details.</b> The Customer acknowledges and agrees that the Customer’s access to the Service is protected by an identification system that requires each User to choose a password and unique username (“Login Details”). It is the responsibility of the Customer to maintain the confidentiality of the Login Details, and not disclose or share the Login Details with any third party, and that the Login Details are kept private and confidential. Any use of such Login Detail shall therefore be deemed to be made by the Customer. The Customer is responsible for immediately notifying Speedflow of any breach of the Customer’s account’s Login Details to take all necessary steps to prevent or terminate the fraudulent use of the Service. Speedflow shall not be liable for any loss that the Customer may incur as a result of unauthorized use of the Login Details with or without our knowledge.
            </p>
            <p>
                3.5 <b>Enforcing Security.</b> Actual or attempted unauthorized use of the Service may result in criminal and/or civil prosecution by the appropriate legal and judicial authorities. Speedflow reserves the right to view, monitor the Service without notice to the fullest extent permitted by applicable law. Any information obtained by monitoring, reviewing, or recording is subject to our Privacy Policy.
            </p>
            <p>
                <b>4. Intellectual Property</b>
            </p>
            <p>
                4.1 <b>Speedflow Ownership.</b> Customer agrees that Speedflow owns all rights, titles and interests in and to all Intellectual Property rights in the Service, the Site, the Documentation, as well as any content herein, except as otherwise stated (“Speedflow Intellectual Property”). All rights not expressly granted to the Customer are reserved by Speedflow. Speedflow owns the Usage Data derived from the operation of the Service. Nothing contained herein shall prevent Speedflow from utilizing the Usage Data for purposes of providing and maintaining, delivering, and/or improving the Service provided that any data so used shall be de-identified and presented in the aggregate so that it will not disclose the identity of Customer to any third party. Speedflow reserves all rights not expressly granted to Customer in these Terms and Conditions. Nothing in this Agreement grants, by implication, waiver, estoppel, or otherwise, to Customer or any third party, any intellectual property rights or other right, title, or interest in or to the Speedflow Intellectual Property.
            </p>
            <p>
                4.2 <b>Customer Data.</b> Speedflow agrees, as between the Parties, the Customer retains all rights, titles, and interests in and to all Intellectual Property rights in the Customer Data. Customer acknowledges that Speedflow is not responsible for the creation, content, or use of the Customer Data by Customer or any third party.
            </p>
            <p>
                4.3 <b>Use of Trademarks.</b> The Customer grants Speedflow the right to use and reproduce the Customer’s name, logo and trademark to identify Customer as a Speedflow customer on the Site and other marketing materials. In the event, the Customer does not agree with the use of the Customer’s name, logo and /or trademark to identify the Customer as a Speedflow customer or that the use of Customer’s name, logo and/or trademark is not in line with the Customer’s branding or similar guidelines, the Customer shall notify Speedflow at the email address info@nexamsg.com
            </p>
            <p>
                4.4 <b>Software.</b> Use of the Services requires and includes use of Mediacore software.  For more information contact info@nexamsg.com
            </p>
            <p>
                <b>5. Acceptable Use Policy and Privacy Policy</b>
            </p>
            <p>
                5.1 <b>Restricted Activities.</b> The Customer is responsible for any use of Services and platform. Customer must not use Services and Platform to:
            </p>
            <p>
                (a) violate any laws, regulations, governmental orders, industry standards, or published policy, or any applicable third-party policy;
            </p>
            <p>
                (b) violate or infringe on Intellectual Property, privacy or rights of another;
            </p>
            <p>
                (c) to transmit or store any content or communications (commercial or otherwise) that is illegal, harmful, unwanted, inappropriate, or objectionable in the recipient’s jurisdiction including content or communication which Speedflow determines (i) is false or inaccurate; (ii) is hateful or encourages hatred or violence against individuals or groups; (iii) could endanger public safety, or (iv) the sale or promotion of prohibited substances, prescription medicines or prohibited goods;
            </p>
            <p>
                (d) to transmit or store any content or communications that is sexually explicit, pornographic, obscene, or other content which is objectionable in Speedflow’s sole discretion;
            </p>
            <p>
                (e) to sell, trade, resell or otherwise exploit account, Services for any unauthorized commercial purpose;
            </p>
            <p>
                (f) stalk, harass, harm another individual or impersonate another individual, misrepresent or falsely identify, engage in spamming, phishing or pharming;
            </p>
            <p>
                (g) to engage in fraudulent activity with respect to third parties or otherwise using the Services to bypass identification systems;
            </p>
            <p>
                (h) to engage in spamming or other unsolicited advertising, marketing or other activities in connection with any unsolicited or harassing messages (commercial or otherwise) including but not limited to unsolicited or unwanted text messages;
            </p>
            <p>
                (i) to engage in unauthorized commercial activities and/or sales and unsolicited marketing or other activities that violate anti-spam laws and regulations, data protection or privacy related legislations in other jurisdictions;
            </p>
            <p>
                (j) to use any robot, spider, another automatic device, or manual process to monitor, copy or ‘scrape’ web pages or the content contained in the website or for any other unauthorized purpose;
            </p>
            <p>
                (k) using any device, software, or routine to interfere or attempt to interfere with the proper working of the Service;
            </p>
            <p>
                (l) to decompile, reverse engineer, or disassemble the software or attempting to do so;
            </p>
            <p>
                (m) perform automated communications or engage in abusive practices including bulk messages in violation of any Applicable Laws;
            </p>
            <p>
                (n) send messages relating to (i) high-risk financial services, including but not limited to payday loans, short term high-interest loans, third-party loans (third party means originating from any party other than the one which will service the loan), student loans, cryptocurrency; (ii) third-party lead generation services that buy, sell or share customer information; (iii) debt collection or forgiveness including but not limited to third-party (i.e., originating from any party other than the one who is owed the debt) debt collection, debt consolidation, debt reduction, credit repair programs; (iv) ‘Get rich quick’ scheme including but not limited to deceptive work from home programs, risk investment opportunities, pyramid schemes; (v) sale, promotion or offering for sale cannabis, CBD or vape products, regardless of whether the messages explicitly contain cannabis terms, images or links to cannabis websites; (vi) sale, promotion or offering for sale prescription drugs; (vii) sale or promotion of gambling including but not limited to casino apps and gambling websites; (viii) sale or promotion or offering for sale firearms or explosives etc.;
            </p>
            <p>
                (o) send any communications on behalf of, or purporting to originate on behalf of Speedflow.
            </p>
            <p>
                5.2 <b>Privacy Policy.</b> Speedflow complies with its privacy policy, available at https://speedflow.com/privacy-policy/ ("Privacy Policy"), in providing the Service. The Privacy Policy is subject to change as described therein. By accessing, using, and providing information to or through the Platform and Service, you acknowledge that you have reviewed and accepted our Privacy Policy, and you consent to all actions taken by us with respect to your information in compliance with the then-current version of our Privacy Policy.
            </p>
            <p>
                5.3 <b>Messaging Compliances.</b> Notwithstanding anything set forth in this Agreement, and in addition thereto, Customer’s use of the Service, must comply with all consent/opt-in requirements, ongoing consent requirements, opt-out/unsubscribe requirements, and correct identification of sender requirements as mentioned in any and all local laws, regulations, and third-party policies. Further, notwithstanding anything set forth in this Agreement and in addition thereto, Customer’s use of the services must comply with any and all local laws and regulations including local notice requirements. Further, the Customer shall process any do not text requests in accordance with the local laws, regulations and third-party policies.
            </p>
            <p>
                5.4. Customer will be solely responsible for any legal liability arising out of or related to SMS Content and Customer Services (whether transmitted on its own or on any Third Party’s behalf). Customer acknowledges that Speedflow has no control over the Information, which passes through the use of the Services and that Speedflow does not examine the use of such Information or the nature or the source of the Information. Customer shall be solely liable for the content of the Information and any other material transmitted by Customer or anyone else using the Services. Speedflow excludes all liability of any kind in connection with the transmission of such content.
            </p>
            <p>
                5.5 <b>Breach of Terms and Conditions.</b> In the event, the Customer breaches any of the terms of the Terms and Conditions, Speedflow may immediately suspend or terminate all or part of the access of the Service and report and share relevant information of any activities deemed illegal or against applicable laws and regulations to the appropriate authorities in accordance with applicable data privacy laws and regulations. In addition, Speedflow reserves the right to impose limits on the Customer’s usage of the Service. The Customer acknowledges that Speedflow may, but is not required to, pre-screen any messaging content but Speedflow shall have the right, in its sole discretion, to pre-screen and/or refuse the transmission of any messaging content that violates this Terms and Conditions.
            </p>
            <p>
                <b>6. Pricing Policy</b>
            </p>
            <p>
                <b>Messaging Rates</b> During Message Content creation Customer can see the amount of SMS parts, amount of used charts and amount of charts in SMS for one SMS in bits, depending on encoding (7bit available only). Details show SMS preview and how it will be split.
            </p>
            <p>
                Customer acknowledges that functionality can be expanded and changed at any time without prior notice).
            </p>
            <p>
                For the avoidance of doubt, Speedflow may change the Messaging Rates at any time and will endeavor, but is not required to, to provide notice of such change prior to its implementation. Customers may request the Messaging Rates currently applicable by sending a request to info@nexamsg.com
            </p>
            <p>
                <b>7. Payment.</b>
            </p>
            <p>
                Customer shall be solely responsible for the consequences of any expired or incomplete Payment Method information and insufficient funds to pay for the Services including charges incurred as a result of such attempts to charge. Payment is due in full in advance to start using the Services. The Customer agrees that it shall be responsible for any and all fees, if any, associated with payment for the Services.
            </p>
            <p>
                <b>Taxes.</b> Unless otherwise stated, the pricing does not include taxes, levies, duties, or assessments including value-added, sales, use or withholding taxes assessable by any local, state, or provincial authorities (“Taxes”). The Customer is responsible for paying all applicable Taxes associated with the purchases hereunder.
            </p>
            <p>
                <b>Refunds.</b>  There will be no refunds or credits for partial or non-use of Service.
            </p>
            <p>
                <b>8. Protection of Data</b>
            </p>
            <p>
                Speedflow will take commercially reasonable administrative, physical, and technical safeguards for the protection of the security, confidentiality, and integrity of Customer’s Data, complying in any case with Applicable Laws and regulations.
            </p>
            <p>
                <b>9. Confidentiality</b>
            </p>
            <p>
                9.1 <b>Obligation of Confidentiality.</b> Each Party (“Receiving Party”) shall not, without the other Party’s (“Disclosing Party”) prior written consent, disclose or permit the disclosure of any Confidential Information provided by the Disclosing Party to the Receiving Party under or in connection with this Terms and Conditions, except that the Confidential Information may be disclosed to the Receiving Party’s employees, Affiliates, subcontractors or professional advisors to the extent necessary to carry out its duties and obligations under this Agreement. The Parties (including their respective employees and agents) agree to hold each other’s Confidential Information in confidence during the term of this Agreement and for three (3) years thereafter. The Parties further agree not to disclose or make any Confidential Information of the other Party available in any form to any third party other than its employees, Representatives, Affiliates, legal counsel, professional advisors, consultants, accountants on a need-to-know basis or to use it for any purpose other than the implementation of this Terms and Conditions.
            </p>
            <p>
                9.2 <b>Compelled by Law.</b> If the Receiving Party or any of its Representatives is compelled by applicable law to disclose any Confidential Information then, to the extent permitted by applicable law, the Receiving Party shall provide prior notice of such compelled disclosure and reasonable assistance, at the other party’s cost if the other party wishes to contest the disclosure.
            </p>
            <p>
                9.3 <b>Return of Confidential Information.</b> Upon termination or suspension of Services, each Party shall, upon the other Party’s request, return to the other Party, or destroy, the Confidential Information and any copies thereof.
            </p>
            <p>
                <b>10. Disclaimer</b>
            </p>
            <p>
                <b>DISCLAIMER.</b> TO THE FULLEST EXTENT PERMITTED BY LAW, CUSTOMER AGREES THAT THE SERVICES ARE BEING PROVIDED ‘AS IS’ AND ‘AS AVAILABLE’, AND EXCEPT FOR THE WARRANTIES EXPRESSLY SET FORTH IN THIS AGREEMENT, SPEEDFLOW MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING OR USAGE IN TRADE, TOGETHER WITH SIMILAR WARRANTIES, WHETHER ARISING UNDER ANY LAW OR OTHERWISE. SPEEDFLOW DOES NOT REPRESENT OR WARRANT THAT THE SERVICES WILL ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, CONTINUOUS, ERROR-FREE OR WILL OPERATE WITHOUT PACKET LOSS, NOR DOES SPEEDFLOW WARRANT ANY CONNECTION TO OR TRANSMISSION FROM THE INTERNET.
            </p>
            <p>
                <b>11. Limitations of Liability</b>
            </p>
            <p>
                CUSTOMER AGREES THAT SPEEDFLOW WILL NOT BE LIABLE FOR ANY (1) USE OF THE SERVICES, (2) LOSS OR LIABILITY RESULTING FROM ACTS OF OR EVENTS BEYOND SPEEDFLOW CONTROL INCLUDING, BUT NOT LIMITED TO ANY ERRORS OR TECHNICAL ISSUES OF ANY DOMAIN NAME REGISTRY OR OTHER THIRD PARTY PROVIDER, (3) DATA NON-DELIVERY, MIS-DELIVERY, CORRUPTION, DESTRUCTION OR OTHER MODIFICATION; (4) OR LOSS OR LIABILITY RESULTING FROM THE UNAUTHORIZED USE OR MISUSE OF CUSTOMER ACCOUNT IDENTIFIER OR PASSWORD.
            </p>
            <p>
                SPEEDFLOW WILL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST PROFITS) OR FOR ANY FINANCIAL OR ECONOMIC LOSS OR FOR LOSS OF PROFITS, LOSS OF BUSINESS, DEPLETION OF GOODWILL OR SIMILAR LOSSES, LOSS OF ANTICIPATED SAVINGS OR LOSS OR CORRUPTION OF DATA OR INFORMATION, REGARDLESS OF THE FORM OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EVEN IF SPEEDFLOW HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p>
                <b>12. Revisions</b>
            </p>
            <p>
                The materials appearing on the Site may include technical, typographical, or photographic errors. Speedflow does not warrant that any of the materials on the Site are accurate, complete, or current. Speedflow may make changes to the materials contained on the Site at any time without notice. Speedflow however, makes no commitment of any kind to update the materials regularly.
            </p>
            <p>
                <b>13. Assumption of Risk</b>
            </p>
            <p>
                The Customer uses the Internet solely at its own risk and subject to all applicable local, state, national, and international laws and regulations. While Speedflow has endeavored to create a secure and reliable Service, please be advised that the confidentiality of any communication or material transmitted to/from a Service over the Internet cannot be guaranteed. Accordingly, Speedflow is not responsible for the security of any information transmitted via the Internet, the accuracy of the information contained on the Site, or for the consequences of any reliance on such information. Speedflow shall have no liability for interruptions or omissions in Internet, network or hosting services. The Customer assumes the sole and complete risk of using the Service. Any material downloaded or otherwise obtained through the use of the Service is done at the Customer’s own discretion and risk. The Customer will be solely responsible for any damage to its computer system or loss of data that results from the download of any such material.
            </p>
            <p>
                <b>14. Links</b>
            </p>
            <p>
                The Site may include links to certain website, materials, or content developed by third parties. Speedflow has not reviewed all of the sites linked to its Internet Site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Speedflow of the site. Use of any such linked website is at the Customer’s own risk. Speedflow reserves the right in its sole and absolute discretion to discontinue links to any other sites at any time and for any reason.
            </p>
            <p>
                <b>15. Compliance with Laws</b>
            </p>
            <p>
                The Customer will use the Service in full compliance with all applicable laws and regulations with regard to the use of the Service. If necessary and in accordance with applicable law, Speedflow will cooperate with local, state and/or international government authorities to protect its Site, Materials, Content, Services, Accounts, visitors, customers, its parents, subsidiaries, affiliates and their respective members, managers, directors, officers, employees, stockholders and agents and operational providers, from the unauthorized use of its Site, Materials, Content, Services and Accounts.
            </p>
            <p>
                <b>16. Indemnification</b>
            </p>
            <p>
                The Customer shall, at its own expense and subject to the limitations set forth in this Clause, indemnify and defend Speedflow and its Affiliates from and against any and all third-party claims arising out of (a) Customer’s breach of the terms of this Terms and Conditions and accompanying policies, (b) claims of violation of any laws, regulations or rights of a third party by the Customer, (c) claims related to Customer Data and/or claims relating to data transferred to the third parties or (d) claims that arise from the actions of the Customer in connection with its use of the Service, and hold Speedflow harmless from and against any and all liability, losses, damages, and costs (including attorneys’ fees) or agreed in settlement by Customer resulting from such claims.
            </p>
            <p>
                <b>17. Term and Termination of License</b>
            </p>
            <p>
                17.1 <b>Term.</b> The term of this Agreement shall begin from the moment the Customer accepts this Terms and Conditions and creates its account.
            </p>
            <p>
                17.2 <b>Suspension or Termination of Customer’s Account.</b> Speedflow may suspend or terminate access to the Service immediately if Customer is in material breach of these Terms and Conditions including using the Services in violation of law or applicable regulations. Speedflow shall not be responsible for any damages arising from the suspension or termination of the Service.
            </p>
            <p>
                <b>18. Governing Law and Jurisdiction</b>
            </p>
            <p>
                This Terms and Conditions and business relationships shall be governed by and construed in accordance with the law of Republic of Bulgaria.
            </p>
            <p>
                All disputes emerged will be settled by the Arbitration court by the Association for development of the law in the town of Plovdiv, according to its Regulations for lawsuits based on arbitration agreements. The current arbitration clause does not hinder the parties in the contract to bring the case to the competent state courts in Bulgaria. In this case the courts in Plovdiv have international competence. It is left to the choice of the claimant to which of these courts they will refer the dispute.
            </p>
            <p>
                <b>19. Waivers</b>
            </p>
            <p>
                No waiver shall be deemed to have been made by either party unless expressed in writing and signed by the waiving party. Any such written waiver will not constitute a waiver of any prior or future obligations to comply with such term, provision, condition or requirement.
            </p>
            <p>
                <b>20. No Injunctive Relief</b>
            </p>
            <p>
                In no event shall the Customer seek or be entitled to rescission, injunctive or other equitable relief, or to enjoin or restrain the operation of the Service, exploitation of any advertising or other materials issued in connection therewith, or exploitation of the Service or any content or other material used or displayed through the Service.
            </p>
            <p>
                <b>21. Miscellaneous</b>
            </p>
            <p>
                21.1 <b>Notices.</b> Except as set out in this Agreement, notices or other communications must be in English and are deemed to have been fully given when made in writing and delivered via email, and for Speedflow, addressed to info@nexamsg.com and to Customer at email address associated with the Customer’s account.
            </p>
            <p>
                21.2. <b>Independent Contractors.</b> The Parties are independent contractors and have no right to assume or create any obligation or responsibility on behalf of the other party. These Terms and Conditions does not create or imply any partnership, agency, or joint venture.
            </p>
            <p>
                21.3. <b>Force Majeure.</b> Neither Party is liable to the other for any delay or failure to perform hereunder due to circumstances beyond such Party’s reasonable control including acts of God, acts of government, flood, fire, earthquakes, civil unrest, acts of terror, strikes, service disruptions involving hardware, software or power systems not within such party’s possession or reasonable control, and denial of service attacks.
            </p>
            <p>
                21.4. <b>Severability.</b> If any provision of these Terms and Conditions is found to be unenforceable or invalid, that provision will be limited to the minimum extent necessary so that these Terms and Conditions will otherwise remain enforceable and in full force and effect.
            </p>
            <p>
                21.5 <b>Amendments.</b> Speedflow reserves the right to update or change these Terms and Conditions or any of its policies from time to time without any notice. It is the Customer’s responsibility to check this Terms and Conditions on the regular basis. Any use of the Service after the change in the Terms and Conditions or any of its policies will be deemed as Customer’s acceptance of such updates.
            </p>
        </div>
    )
}