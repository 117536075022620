import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { _campaignGetById } from '../../services/apiConnections.service';

export const CampaignView = () => {

    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [campaign, setCampaign] = useState({});

    const params = useParams();

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        } else {
            const getData = async (token) => {
                let result = await _campaignGetById(token, params.id);
                if (result.error) {
                    console.log("--result:", result);
                    console.log("--error:", result.error);
                } else {
                    console.log("--result:", result);
                    setCampaign(result);
                }
                setError(result.error);
            };
            getData(token);
        }
    }, [token, params]);

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>Campaign</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='pageBody'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className="mb-3">
                                        <label className='wizardLabel'>Campaign Name</label>
                                        <span className='wizardValue'>{campaign.name}</span>
                                    </div>
                                    <div className="mb-3">
                                        <label className='wizardLabel'>Sender</label>
                                        <span className='wizardValue'>{campaign.sender}</span>
                                    </div>
                                    <div className="mb-3">
                                        <label className='wizardLabel'>Message</label>
                                        <span className='wizardValue'>{campaign.text}</span>
                                    </div>
                                    <div className="mb-3">
                                        <label className='wizardLabel'>Messages Count</label>
                                        <span className='wizardValue'>{campaign.messages_count}</span>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="mb-3">
                                        <label className='wizardLabel'>Recipients</label>
                                        <span className='wizardValue'>
                                            <ul>
                                                {campaign.recipients && campaign.recipients.map((e, index) => {
                                                    return <li key={index}>{e.name} - {e.number}</li>
                                                })}
                                            </ul>
                                        </span>
                                    </div>
                                    <div className="mb-3">
                                        <label className='wizardLabel'>Price</label>
                                        <span className='wizardValue'>${campaign.price}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};