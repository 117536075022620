import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Select from 'react-select';
import moment from 'moment/moment';

import { _campaignDelete, _campaignsGet } from '../../services/apiConnections.service';
import { ModalDialog } from '../elements/ModalDialog.component';

export const Campaigns = () => {

    const [campaigns, setCampaigns] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [emptyTable, setEmptyTable] = useState(false);
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [campaignToDelete, setCampaignToDelete] = useState({});
    const [stateFilter, setStateFilter] = useState({
        label: 'All',
        value: -1
    });

    let states = [
        {
            label: 'All',
            value: -1
        },
        {
            label: 'Draft',
            value: 0
        },
        {
            label: 'Active',
            value: 1
        },
        {
            label: 'In Progress',
            value: 2
        },
        {
            label: 'Finished',
            value: 3
        },
    ]

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        if (token && !campaigns.length && !emptyTable) {
            getData(token);
        }
    }, [token, campaigns, emptyTable]);

    useEffect(() => {
        filterTable(stateFilter);
    }, [allCampaigns]);

    const getData = async (token, search = '') => {
        let result = await _campaignsGet(token, search);
        if (result.error) {
            console.log(`--error: ${result.error}`);
        } else {
            setEmptyTable(!result.campaigns.length);
            setCampaigns(result.campaigns);
            setAllCampaigns(result.campaigns);
        }
        setError(result.error);
    };

    const handleFilter = (value) => {
        getData(token, value);
    };

    const filterTable = (state) => {
        if (state.value === -1) {
            setCampaigns(allCampaigns);
        } else {
            let filteredCampaigns = allCampaigns.filter((campaign) => { return campaign.status === state.value });
            setEmptyTable(!filteredCampaigns.length);
            setCampaigns(filteredCampaigns);
        }
    };

    const viewFormatter = (cell, row) => {
        return (
            <Link className='campaignViewBtn' to={`/campaigns/${cell}`}>
                <FontAwesomeIcon icon={regular('eye')} />
            </Link>
        )
    };

    const startAtFormatter = (cell, row) => {
        return moment(new Date(cell)).format('yyyy-MM-DD H:mm');
    };

    const stateFormatter = (cell, row) => {
        let status = "";
        switch (cell) {
            case 0:
                status = "Draft";
                break;
            case 1:
                status = "Active";
                break;
            case 2:
                status = "In Progress";
                break;
            case 3:
                status = "Finished";
                break;

            default:
                break;
        }
        return status;
    };

    const editFormatter = (cell, row) => {
        return (
            <Link className='tableIconBtn' to={`/campaigns/edit-campaign/${Number(row.ID)}`}>
                <FontAwesomeIcon icon={solid('pencil')} />
            </Link>
        )
    };

    const deleteFormatter = (cell, row) => {
        return (
            <button
                className='tableIconBtn trashBtn'
                onClick={() => {
                    setCampaignToDelete(row);
                    setShowDeleteDialog(true);
                }}>
                <FontAwesomeIcon icon={regular('trash-can')} />
            </button>
        )
    };

    const handleDeleteCampaign = async (id) => {
        await _campaignDelete(token, id);
        getData(token);
        setShowDeleteDialog(false);
    };

    const columns = [
        {
            dataField: 'ID',
            text: 'View',
            classes: 'iconColumn',
            formatter: viewFormatter
        },
        {
            dataField: 'name',
            text: 'Campaign',
            sort: true
        },
        {
            dataField: 'start_at',
            text: 'Scheduled',
            formatter: startAtFormatter
        },
        {
            dataField: 'status',
            text: 'State',
            formatter: stateFormatter
        },
        {
            dataField: 'template_id',
            text: 'Edit',
            classes: 'iconColumn',
            formatter: editFormatter
        },
        {
            dataField: 'user_id',
            text: 'Delete',
            classes: 'iconColumn',
            formatter: deleteFormatter
        }
    ];

    const defaultSorted = [
        {
            dataField: 'name',
            order: 'asc'
        }
    ];

    const noData = () => {
        return <p className='emptyTableMessage'>You have no campaigns yet! Create it</p>
    };

    return (
        <div className='content wideContent'>
            <div>
                <div className='pageTitle'>
                    <span>Campaigns</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='myTable'>
                            <div className='tableHeader tableHeaderInline'>
                                <div className="tableFiltersCol">
                                    <div className='filterCol'>
                                        <label
                                            className='inputLabel'
                                            htmlFor='campaignFilter'>Campaign</label>
                                        <input
                                            name='campaignFilter'
                                            className='searchInput'
                                            placeholder='Search...'
                                            onChange={(event) => handleFilter(event.target.value)} />
                                    </div>
                                    <div className='filterCol'>
                                        <label
                                            className='inputLabel'
                                            htmlFor='state'>State</label>
                                        <Select
                                            id='state'
                                            name='state'
                                            className='customSelect'
                                            classNamePrefix='select'
                                            options={states}
                                            value={stateFilter}
                                            onChange={(selectedOption) => {
                                                setStateFilter(selectedOption);
                                                filterTable(selectedOption);
                                            }} />
                                    </div>
                                </div>
                                <div className='tableBtnCol'>
                                    <Link className='mainBtn btn' to={'/create-campaign'}>Create Campaign</Link>
                                </div>
                            </div>
                            <BootstrapTable
                                keyField='ID'
                                data={campaigns}
                                columns={columns}
                                wrapperClasses='table-responsive'
                                bordered={false}
                                pagination={paginationFactory()}
                                defaultSorted={defaultSorted}
                                noDataIndication={noData} />
                        </div>
                    </div>
                </div>
                <ModalDialog
                    show={showDeleteDialog}
                    onHide={() => setShowDeleteDialog(false)}
                    onAccept={async () => handleDeleteCampaign(campaignToDelete.ID)}
                    title={`Delete Campaign ${campaignToDelete.name}`}
                    acceptBtnText="Delete">
                    <span>{`Are you sure you want to delete campaign '${campaignToDelete.name}'?`}</span>
                </ModalDialog>
            </div>
        </div>
    );
}