import React from "react";

export const LogoutBtn = (props) => {

    const handleLogout = () => {
        console.log("logout");
        localStorage.removeItem('token');
        props.navigate('/log-in');
    };
    return (
        <button
            className='mainBtn'
            onClick={handleLogout}>Log Out</button>
    );
};