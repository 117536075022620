import React, { useEffect, useState } from "react";

import { MenuLink } from "./menuLink.component";
import { useLocation } from "react-router-dom";

export const SideMenu = () => {

    const location = useLocation();
    const [url, setUrl] = useState(null);

    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    return (
        <div className='sideMenu'>
            <ul>
                <li className={(url === '/dashboard' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/dashboard' icon='dashboard' name='Dashboard' picIcon />
                </li>
                <li className={(url === '/prices' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/prices' icon='prices' name='Prices' />
                </li>
                <li className={(url === '/sms-templates' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/sms-templates' icon='sms-template' name='SMS Templates' />
                </li>
                <li className={(url === '/send-sms' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/send-sms' icon='send-sms' name='Send SMS' />
                </li>
                <li className={(url === '/contacts' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/contacts' icon='contacts' name='Contacts' picIcon />
                </li>
                <li className={(url === '/campaigns' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/campaigns' icon='campaigns' name='Campaigns' picIcon />
                </li>
                <li className={(url === '/campaign-sms-report' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/campaign-sms-report' icon='individual-sms-report' name='Campaign SMS Report' />
                </li>
                <li className={(url === '/individual-sms-report' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/individual-sms-report' icon='individual-sms-report' name='Individual SMS Report' />
                </li>
                <li className={(url === '/support' ? 'activeMenuLink' : '')}>
                    <MenuLink to='/support' icon='support' name='Support' />
                </li>
            </ul>
        </div>
    );
};