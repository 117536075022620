import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import { LogoutBtn } from "./LogoutBtn.component";
import { SideMenu } from "./SideMenu.component";

export const MainPage = (props) => {
    const [userName, setUserName] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setUserName(user.first_name);
        }
    }, []);

    return (
        <div className='mainPage'>
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <Link className="logo" to={'/'}>
                        <img src='/logo192.png' alt='logo' />
                    </Link>
                    <div className='headerRightBlock'>
                        <Link className='profileLink' to={'/profile'}>
                            <FontAwesomeIcon icon={solid('user')} />
                        </Link>
                        <LogoutBtn navigate={props.navigate} />
                    </div>
                </div>
            </nav>
            <div className='contentWrapper'>
                <SideMenu />
                <div className='mainBlock'>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};