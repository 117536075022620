import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { _pricesGet } from '../../services/apiConnections.service';

export const Prices = () => {

    const [prices, setPrices] = useState([]);
    const [emptyTable, setEmptyTable] = useState(false);
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!token) {
            setToken(localStorage.getItem('token'));
        }
        if (token && !prices.length && !emptyTable) {
            getData(token);
        }
    }, [token, prices, emptyTable]);

    const getData = async (token, search = '') => {
        let result = await _pricesGet(token, search);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setEmptyTable(!result.price_list.prices.length);
            setPrices(result.price_list.prices);
        }
        setError(result.error);
    };

    const columns = [
        {
            dataField: 'name',
            text: 'Destination',
            sort: true
        },
        {
            dataField: 'direction',
            text: 'Code',
            sort: true
        },
        {
            dataField: 'cost',
            text: 'Price/SMS',
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === "asc") {
                    return Number(a) - Number(b);
                } else if (order === "desc") {
                    return Number(b) - Number(a);
                }
            }
        }
    ];

    const defaultSorted = [
        {
            dataField: 'name',
            order: 'asc'
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total"> Showing {from} to {to} of {size} Results</span>
    );

    const handleFilter = (value) => {
        getData(token, value);
    };

    const options = {
        hidePageListOnlyOnePage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: '10', value: 10
            },
            {
                text: '25', value: 25
            },
            {
                text: '50', value: 50
            },
            {
                text: '100', value: 100
            }
        ]
    };

    const noData = () => {
        return <p className='emptyTableMessage'>No matching records found</p>
    };

    return (
        <div className='content wideContent'>
            <div>
                <div className='pageTitle'>
                    <span>Prices</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='myTable'>
                            <div className='tableHeader'>
                                <input
                                    className='searchInput'
                                    placeholder='Search...'
                                    onChange={(event) => handleFilter(event.target.value)} />
                            </div>
                            <BootstrapTable
                                keyField='ID'
                                data={prices}
                                columns={columns}
                                wrapperClasses='table-responsive'
                                bordered={false}
                                pagination={paginationFactory(options)}
                                defaultSorted={defaultSorted}
                                noDataIndication={noData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}