import React from "react";
import { Navigate, useLocation } from "react-router-dom";

export const RequireAuth = (props) => {
    let location = useLocation();

    if (props.token === null) {
        console.log("navigate to login");
        return <Navigate to="/log-in" state={{ from: location }} replace />
    }
    return props.children;
};