export const HandleError = (error) => {
    let status = error.response.status;

    console.log(error.message);

    switch (status) {
        case 401: {
            localStorage.removeItem('token');
            window.location.assign('/log-in');
            break;
        }
        default:
            break;
    }
};