import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { faAddressBook, faFileLines, faSmileBeam } from '@fortawesome/free-regular-svg-icons';
import { faFolder, faMoneyBill1, faPaperPlane, faQuestionCircle, faFlag, faEnvelope, faDashboard, faTableList } from '@fortawesome/free-solid-svg-icons';

// import dasboardIcon from "../../images/icons/dashboard.png";

export const MenuLink = (props) => {

    const defindIcon = (icon) => {
        switch (icon) {
            case "prices":
                return faMoneyBill1;
            case "blacklist":
                return faFolder;
            case "send-sms":
                return faPaperPlane;
            case "support":
                return faQuestionCircle;
            case "sms-template":
                return faEnvelope;
            case "individual-sms-report":
                return faTableList;

            default:
                return faSmileBeam
        };
    };

    const defindPicIcon = (icon) => {
        switch (icon) {
            case "dashboard":
                return "/icons/dashboard.png";
            case "logs":
                return "/icons/logs.png";
            case "campaigns":
                return "/icons/campaigns.png";
            case "contacts":
                return "/icons/contacts.png";

            default:
                return;
        };
    };

    return (
        <Link className='nav-link' to={props.to}>
            {props.picIcon ?
                <div className='nav-icon' style={{ backgroundImage: `url(${defindPicIcon(props.icon)})` }}></div>
                :
                <FontAwesomeIcon className='nav-icon' icon={defindIcon(props.icon)} />
            }
            <span className='nav-name'>{props.name}</span>
        </Link>
    );
}