import React from "react";
import { Outlet } from "react-router-dom";

export const AuthLayouts = () => {
    return (
        <div className='auth-container'>
            <div className="auth-logo">
                <img className="logo" src="logo192.png" alt="logo"></img>
            </div>
            <div className="auth-wrapper">
                <div className="auth-inner">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};